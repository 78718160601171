<script>
import api from '@/command/api'
import DetailFormGroup from '../../../components/DetailFormGroup'
import { postAction, getAction } from '@/command/netTool'
import moment from 'moment'
import { relateTypeList, goodsTypeList } from '@/utils/textFile'

export default {
  name: 'indexBannerDetail',
  data() {
    return {
      ...api.command.getState(),
      detail: {
        imgUrl1: '',
      },
      selectData: [],
      storeData: [],
      goodsTypeData: [],
      linkAddress: [],
      storeDataActive: [],
      luckyDrawList: [],
      liveList: [],
      projectPageList: [],
    }
  },
  watch: {
    'detail.goodsType'(newData, oldData) {
      if (newData) {
        this.handleGoodsType()
      }
    },
  },
  mounted() {
    const { id } = this.$route.query

    getAction('/api/config/farmKingKongItem/list').then((result) => {
      if (result.code == 200) {
        this.selectData = result.data.map((e) => {
          return {
            ...e,
            value: e.code,
          }
        })
      } else {
        this.$message.error(result.msg)
      }
    })

    if (id) {
      api.command.getDetail
        .call(this, {
          url: `/market/farmBanner/get?id=${id}`,
        })
        .then((result) => {
          if (result.imgUrl) {
            const imgUrlArr = result.imgUrl.split(',')
            this.detail.imgUrl1 = imgUrlArr[0]
            // this.detail.showType = result.showType.split(',')
          }
          this.$forceUpdate()
        })
    }

    getAction('/api/config/farmGoodsCategory/list').then((result) => {
      if (result.code == 200) {
        this.linkAddress = result.data.map((e) => {
          return {
            ...e,
            value: e.id,
          }
        })
      } else {
        this.$message.error(result.msg)
      }
    })

    this.storeSearch()
  },
  methods: {
    async handleGoodsType(value = '', index) {
      let searchResult = await api.command.search.call(this, {
        url: `/api${
          [
            '/goods/farmAgritainment',
            '/goods/farmHomeStay',
            '/goods/farmFarmingExperience',
            '/commodity/farmNativeProduct',
          ][this.detail.goodsType]
        }/list?name=${value}`,
      })
      this.goodsTypeData = searchResult.map((el) => {
        return {
          name: el.name + '    ' + el.mainBodyName,
          value: el.id,
        }
      })
    },
    async handleLuckyDrawSearch(value = '') {
      let searchResult = await api.command.search.call(this, {
        url: `/farmActivityDrawPrize/page?name=${value}`,
        host: '/api',
      })
      this.luckyDrawList = searchResult.records.map((el) => {
        return {
          name: el.name,
          value: el.id,
        }
      })
    },
    async handleProjectPageSearch(value = '') {
      let searchResult = await api.command.search.call(this, {
        url: `/farmShowSpecial/getList?name=${value}`,
        host: '/api',
      })
      this.projectPageList = searchResult.map((el) => {
        return {
          name: el.showName,
          value: el.id,
        }
      })
    },
    async handleLiveList(value = '') {
      let searchResult = await api.command.search.call(this, {
        url: `/market/farmLiveRoom/list?name=${value}`,
        host: '/api',
      })
      this.liveList = searchResult.map((el) => {
        return {
          name: el.name,
          value: el.liveRoomId,
        }
      })
    },
    async storeSearch(value = '') {
      let searchResult = await api.command.search.call(this, {
        url: `/api/commodity/farmMainBody/list?mainBodyName=${value}`,
      })
      this.storeData = searchResult.map((el) => {
        return {
          name: el.mainBodyName,
          value: el.id,
        }
      })
    },
    async storeSearchActive(value = '') {
      let searchResult = await api.command.search.call(this, {
        url: `/api/farmActivity/list?appCode=sznc&name=${value}`,
      })
      this.storeDataActive = searchResult.map((el) => {
        return {
          name: el.name,
          value: el.id,
        }
      })
    },
    getForm1() {
      return {
        title: '基本信息',
        type: 'cardForm',
        data: [
          {
            name: '名称(76字符内)',
            type: 'input',
            key: 'name',
            cols: 12,
            props: {
              maxLength: 76,
            },
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            name: '轮播停留时间',
            type: 'inputNumber',
            key: 'showSecond',
            cols: 12,
            props: {
              addonAfter: 's',
              min: 0,
              max: 100,
            },
          },
          {
            name: '上架时间',
            type: 'datePicker',
            key: 'beginTime',
            cols: 12,
            props: {
              showTime: true,
              format: 'YYYY-MM-DD HH:mm',
              valueFormat: 'YYYY-MM-DD HH:mm:ss',
            },
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            name: '下架时间',
            type: 'datePicker',
            key: 'endTime',
            cols: 12,
            props: {
              showTime: true,
              format: 'YYYY-MM-DD HH:mm',
              valueFormat: 'YYYY-MM-DD HH:mm:ss',
            },
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            name: '排序序号（0-100）',
            type: 'inputNumber',
            key: 'sort',
            cols: 12,
            props: {
              min: 0,
              max: 100,
            },
          },
        ],
      }
    },
    getForm3() {
      let that = this
      return {
        type: 'cardForm',
        title: 'Banner',
        data: [
          {
            type: 'iconUpload',
            key: 'a1',
            typeData: [
              {
                maxLength: 1,
                key: 'imgUrl1',
                desc: '@2x   xhdpi <br/> 上传尺寸636*344px',
              },
            ],
            rules: [
              {
                // eslint-disable-next-line max-params
                validator(rule, value, callback, source, options) {
                  if (!that.detail.imgUrl1) {
                    return false
                  } else {
                    return true
                  }
                },
                message: '请选择图片上传',
              },
            ],
          },
        ],
      }
    },
    getForm2() {
      return {
        title: 'Banner链接',
        type: 'cardForm',
        data: [
          {
            name: '类型',
            type: 'select',
            cols: 12,
            gutter: 10,
            key: 'relateType',
            rules: [
              {
                required: true,
                type: 'string',
              },
            ],
            typeData: relateTypeList,
          },
          ...this.relateTypeData(),
        ],
      }
    },
    relateTypeData() {
      //   this.detail.linkUrl = null
      return [
        {
          name: '链接地址',
          type: 'input',
          cols: 12,
          gutter: 10,
          key: 'linkUrl',
          maxLength: 100,
          display: this.detail.relateType == '1',
          //   props: {
          //     addonBefore: 'http://'
          //   },
          rules: [
            {
              required: true,
            },
          ],
        },
        {
          name: '商品类型',
          type: 'select',
          cols: 4,
          gutter: 10,
          key: 'goodsType',
          display: this.detail.relateType == '3',
          typeData: goodsTypeList(),
          rules: [
            {
              required: true,
            },
          ],
        },
        {
          name: '关联抽奖',
          type: 'dropDownInput',
          cols: 12,
          key: 'relateName',
          onInputSearch: this.handleLuckyDrawSearch,
          valueKey: 'drawPrizeId',
          display: this.detail.relateType == '8',
          inputProps: {
            placeholder: '搜索',
            addonBefore: <a-icon type="search" />,
          },
          rules: [
            {
              required: true,
            },
          ],
          typeData: this.luckyDrawList,
        },
        {
          name: '关联专题页',
          type: 'dropDownInput',
          cols: 12,
          key: 'relateName',
          onInputSearch: this.handleProjectPageSearch,
          valueKey: 'showSpecialId',
          display: this.detail.relateType == '7',
          inputProps: {
            placeholder: '搜索',
            addonBefore: <a-icon type="search" />,
          },
          rules: [
            {
              required: true,
            },
          ],
          typeData: this.projectPageList,
        },
        {
          name: '关联直播',
          type: 'dropDownInput',
          cols: 12,
          key: 'relateName',
          onInputSearch: this.handleLiveList,
          valueKey: 'liveRoomId',
          display: this.detail.relateType == '6',
          inputProps: {
            placeholder: '搜索直播',
            addonBefore: <a-icon type="search" />,
          },
          rules: [
            {
              required: true,
            },
          ],
          typeData: this.liveList,
        },
        {
          name: '关联商品',
          type: 'dropDownInput',
          cols: 8,
          key: 'relateName',
          onInputSearch: this.handleGoodsType,
          valueKey: 'goodsId',
          display: this.detail.relateType == '3',
          inputProps: {
            placeholder: '搜索商品',
            addonBefore: <a-icon type="search" />,
          },
          rules: [
            {
              required: true,
            },
          ],
          typeData: this.goodsTypeData,
        },
        // 跳转小程序
        {
          name: 'appid',
          cols: 12,
          type: 'input',
          key: 'linkUrl',
          display: this.detail.relateType == '9',
          labelAlign: 'left',
          rules: [
            {
              required: true,
            },
          ],
        },
        {
          name: '关联店铺',
          type: 'dropDownInput',
          cols: 12,
          key: 'relateName',
          onInputSearch: this.storeSearch,
          valueKey: 'shopId',
          display: this.detail.relateType == '4',
          inputProps: {
            placeholder: '搜索店铺',
            addonBefore: <a-icon type="search" />,
          },
          rules: [
            {
              required: true,
            },
          ],
          typeData: this.storeData,
        },
        {
          name: '关联活动',
          type: 'dropDownInput',
          cols: 12,
          key: 'relateName',
          onInputSearch: this.storeSearchActive,
          valueKey: 'activityId',
          display: this.detail.relateType == '5',
          inputProps: {
            placeholder: '搜索活动',
            addonBefore: <a-icon type="search" />,
          },
          rules: [
            {
              required: true,
            },
          ],
          typeData: this.storeDataActive,
        },
        {
          name: '链接点',
          type: 'select',
          cols: 12,
          gutter: 10,
          key: 'linkModule',
          display: this.detail.relateType == '2' && this.detail.linkModule != 'product_type',
          typeData: this.selectData,
          rules: [
            {
              required: true,
            },
          ],
        },
        {
          name: '链接点',
          type: 'select',
          cols: 6,
          gutter: 10,
          key: 'linkModule',
          display: this.detail.relateType == '2' && this.detail.linkModule == 'product_type',
          typeData: this.selectData,
          rules: [
            {
              required: true,
            },
          ],
        },
        {
          name: '链接地址',
          type: 'select',
          cols: 6,
          gutter: 10,
          key: 'shopTypeId',
          display: this.detail.relateType == '2' && this.detail.linkModule == 'product_type',
          typeData: this.linkAddress,
          rules: [
            {
              required: true,
            },
          ],
        },
      ].filter((e) => e.display)
    },
    getFoot() {
      const left = [
        {
          name: '提交',
          type: 'primary',
          loading: this.loading,
          sumbit: true,
          onClick: () => {
            if (moment(this.detail.beginTime) > moment(this.detail.endTime)) {
              this.$message.warning('下架时间应在上架时间之后！')
              return
            }

            if (this.detail.relateType == '2' && this.detail.linkModule == 'product_type') {
              this.detail.relateName = (
                this.linkAddress.find((e) => e.id == this.detail.shopTypeId) || {
                  name: '',
                }
              ).name
            }

            let obj = {
              ...this.detail,
              imgUrl: [this.detail.imgUrl1].join(','),
              //   showType: this.detail.showType.join(','),
              showType: 3,
              busId: this.$route.query.busId,
            }
            console.log(obj)
            api.command[this.detail.id ? 'editPost' : 'create']
              .call(this, {
                url: `/market/farmBanner/${this.detail.id ? 'update' : 'add'}`,
                params: {
                  ...obj,
                  type: '1',
                },
                isPost: false,
              })
              .then(() => {
                this.$router.back()
              })
          },
        },
        {
          name: '取消',
          type: 'default',
          onClick: () => this.$router.back(),
        },
      ]
      const right = [
        {
          name: ` ${this.detail.status == '1' ? '下架' : '上架'} `,
          type: 'default',
          onClick: () => {
            postAction(`/market/farmBanner/${this.detail.status == '1' ? 'down' : 'up'}?id=${this.detail.id}`).then(
              (e) => {
                if (e.code == 200) {
                  this.$message.success(`${this.detail.status == '1' ? '下架成功' : '上架成功'}`)
                  setTimeout(() => {
                    this.$router.back()
                  }, 500)
                } else {
                  this.$message.error(e.msg)
                }
              }
            )
          },
        },
        {
          name: '删除',
          popTitle: '确认删除吗?',
          isPop: true,
          type: 'danger',
          ghost: true,
          onClick: () => {
            api.command.delPost.call(this, {
              url: `/market/farmBanner/delete?id=${this.detail.id}`,
              isPost: false,
            })
            setTimeout(() => {
              this.$router.back()
            }, 500)
          },
        },
      ]
      return {
        left,
        right,
      }
    },
  },
  render() {
    return (
      <DetailFormGroup
        form={this.detail}
        foot={this.getFoot()}
        data={[this.getForm1(), this.getForm2(), this.getForm3()]}
      />
    )
  },
}
</script>
<style lang="less" scoped>
.entityInformation-detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}
</style>
